import Noty from 'noty'

export const notification = obj => {
  new Noty({
    type: obj.type,
    text: obj.text,
    progressBar: false,
    layout: 'topCenter',
    timeout: obj.timeout || 4500,
    killer: true,
  }).show()
}
